@media only screen and (max-width: 1660px) {
  .mainContent {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 2vh;
  }
}

@media only screen and (max-width: 860px) {
  .formContent {
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 2vh;

    .ant-flex {
      .scheduleWrapper,
      .scheduleCard {
        width: 100%;
      }
    }
  }

  .ant-drawer .drawer-history {
    width: 350px;
  }

  .pricingContent {
    width: 100%;

    .cardWrapperResult,
    .cardResult,
    .cardWrapper,
    .issuersWrapper,
    .issuersCard {
      width: 100%;
    }
  }

  .cardWrapperSubmit .cardSubmit {
    padding-bottom: 0;

    .cardContent {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &.cardContentResult {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 20px 10px;
      }
    }

    .ant-form-item {
      margin: 10px;
    }
  }

  .submit-button-wrapper {
    display: flex;
    width: 100%;

    .ant-space-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 400px) {
  .formContent {
    & > .ant-flex {
      width: 100%;
      .cardWrapper,
      .scheduleWrapper,
      .scheduleCard,
      .cardWrapper .card {
        width: 100%;
      }
    }
  }
  #root .historyButton {
    right: 50px;
    width: 45px;
    height: 45px;
  }
  #AyDeal {
    & > div:first-child {
      flex-direction: column;
      align-items: flex-start;
    }
    .pricing-product-type-select {
      margin: 0;
    }
  }
}
