@import '/src/styles/colors.scss';

.product__timeline {
  transform: translateX(-24px);
  .ant-timeline-item-right {
    .ant-timeline-item-content {
      justify-content: end;
    }
  }
  .timeline__item {
    padding: 12px 10px;
    border-radius: 8px;
    background-color: $white;
    color: $expertGreen;
    position: relative;
    animation: fadeIn 0.2s ease-in-out forwards;

    &__label {
      font-size: 1.5rem;
      font-weight: 500;
    }
    &__flow-label {
      font-size: 1.5rem;
    }
    &__value {
      font-size: 1.5rem;
      font-weight: bold;
      flex: 1;
      @media screen and (max-width: 1280px) {
        font-size: 1.4rem;
      }
    }
    &--autocall {
      background-color: $white;
      color: $expertGreen;
    }
    &--coupon {
      background-color: $white;
      color: $expertGreen;
    }
    &--maturity {
      background-color: $dynamicOrange;
      color: $white;
    }
    &--finalfixing {
      background-color: $white;
      color: $expertGreen;
    }
    &--issue {
      background-color: $cleverPurple;
      color: $white;
    }
    &--initialfixing {
      background-color: $goldenYellow;
      color: $white;
    }
    &--canceled,
    &--passed {
      background-color: $grey200;
      color: $lightGray;
    }
    &--nextEvent {
      background-color: $organicBlue;
      color: $white;
    }
    &--editing {
      background-color: $lightBlue200;
      color: $expertGreen;
    }
    &--lastRedeem {
      background-color: $dynamicOrange;
      color: $white;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(16px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .timeline__item__actions {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  .ant-timeline-item-label {
    font-size: 1.5rem;
  }
}

.ant-timeline-item.today-event {
  .ant-timeline-item-head {
    background: $goldenYellow;
  }
}
