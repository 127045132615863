@import "/src/styles/colors.scss";
.trade__container {
    padding: 10px 0;
    .grid__column {
        display: grid;
        gap: 10px;
    }
    .content__box {
        margin-top: 20px;
        padding: 28px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.5);
        background-color: $expertGreen;
        .orange_button { 
            background-color: $goldenYellow;
        }
        h4 {
            font-size: 16px;
            color: $goldenYellow;
            margin-bottom: 24px;
        }
        .box__section__title {
            margin-top: 30px;
            margin-bottom: 15px;
            &_auto {
                margin-top: auto;
            }
        }
        .invoices__checkbox {
            padding-bottom: 10px;
        }
        .info__item {
            justify-content: space-between;
            padding: 4px 0;
            @media screen and (max-width: 600px) {
                flex-direction: column;
                align-items: flex-start;
            }
            p, label {
                margin: 0;
                color: #fdfcfc;
                font-size: 1.4rem;
                flex: 1;
            }
            label {
                width: 100px;
                font-weight: 600;
                color: $white;
                flex: 1;
            }
            span {
                color: $white;
            }
            a {
                color: $white;
                &:hover {
                    color: $goldenYellow;
                }
            }
        }
        &-right {
            border-left: solid 1px rgb(7, 49, 55);
        }
    }
    .timeline {
        padding-top: 16px;
        .timeline__btn {
            color: rgb(221, 221, 221);
            &:hover {
                color: $white;
            }
            &__current {
                color: $white;
                font-size: 1.6rem;
                font-weight: 600;
            }
        }
    }
    .ant-timeline-item-last {
        padding-bottom: 0;
        .ant-timeline-item-content {
            min-height: 0;
        }
    }
    .invoice__checkbox {
        margin: 1rem 0;
    }
    .custodians__infos {
        border-radius: 4px;
        &__price {
            font-size: 1.6rem;
            font-weight: 600;
            margin-bottom: 6px;
        }
        &__download {
            font-size: 1.2rem;
        }
        span {
            color: $white;
        }
    }
    .view__trade__btn {
        align-self: flex-end;
    }
    .dragger {
        flex: 1;
    }
}