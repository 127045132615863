.headerItem {
  cursor: pointer;
  color: white;
  margin: 0 30px;
  font-size: 20px;
  font-family: 'Work Sans', sans-serif;
}
.headerItem:hover {
  color: var(--organicBlue);
}
.selected {
  color: var(--goldenYellow);
  margin-bottom: 15px;
  border-bottom: 2px solid white;
}
.logo {
  cursor: pointer;
  padding: 10px 0;
  width: 110px;
}

.ant-menu-item span {
  color: var(--white)
}

@media screen and (max-width: 900px) {
  .header_username {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .logo {
    width: 80px;
  }
  .topnav {
    padding: 0 10px;
  }
}
