body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00252d;

  --goldenYellow: #ffa540;
  --expertGreen: #00252d;
  --organicBlue: #006a99;
  --dynamicOrange: #ff5f45;
  --cleverPurple: #25307f;
  --white: #ffffff;
  --lightSlateGrey: #a8bbbf;
  --lightSlateGrey200: #d6dee1;
}

.goldenYellow {
  background-color: var(--goldenYellow);
}
.goldenYellowWrapper {
  background: linear-gradient(130deg, var(--goldenYellow) 50%, var(--expertGreen) 120%);
}
.goldenYellowColor {
  color: var(--goldenYellow);
}
.expertGreen {
  background-color: var(--expertGreen);
}
.expertGreenWrapper {
  background: linear-gradient(130deg, var(--expertGreen) 50%, var(--expertGreen) 120%);
}
.expertGreenColor {
  color: var(--expertGreen);
}
.organicBlue {
  background-color: var(--organicBlue);
}
.organicBlueWrapper {
  background: linear-gradient(130deg, var(--organicBlue) 50%, var(--expertGreen) 120%);
}
.organicBlueColor {
  color: var(--organicBlue);
}
.dynamicOrange {
  color: white;
  background-color: var(--dynamicOrange);
}
.dynamicOrangeWrapper {
  background: linear-gradient(130deg, var(--dynamicOrange) 50%, var(--expertGreen) 120%);
}
.dynamicOrangeColor {
  color: var(--dynamicOrange);
}
.cleverPurple {
  color: white;
  background-color: var(--cleverPurple);
}
.cleverPurpleWrapper {
  background: linear-gradient(130deg, var(--cleverPurple) 50%, var(--expertGreen) 120%);
}
.cleverPurpleColor {
  color: var(--cleverPurple);
}
.white {
  color: white;
}
.floatRight {
  float: right;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--lightSlateGrey200);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--lightSlateGrey); 
}
