@import '/src/styles/colors.scss';

.main_title_wrap {
  font-size: 3.4rem;
  margin-left: 6px;
  align-items: center;
  .main_page_title {
    color: $white;
    font-size: 3.4rem;
    border-bottom: solid 3px $goldenYellow;
    font-weight: 500;
    padding-bottom: 6px;
    &_leftMargin {
      margin-left: 4px;
    }
  }
  .prefix-wrap {
    margin-right: 10px;
  }
  .suffix-wrap {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1600px) {
  .main_title_wrap .main_page_title,
  .main_title_wrap {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .main_title_wrap .main_page_title,
  .main_title_wrap {
    font-size: 1.8rem;
  }
}
