@import '/src/styles/colors.scss';

.status__select {
  .ant-select-selector {
    border: none;
    outline: none;
  }
  &.booked .ant-select-selector {
    background: $expertGreen300;
  }
  &.order__received .ant-select-selector {
    background: $indigo;
  }
  &.order__received .ant-select-selector {
    background: $lime;
  }
  &.invoice__sent .ant-select-selector {
    background: $deepOrange;
  }
  &.commission__Paid .ant-select-selector {
    background: $blue;
  }
  &.closed .ant-select-selector {
    background: $green-positive;
  }
}
.trade_list_container {
  #custom-table .ant-table-cell-fix-right {
    background-color: rgba($color: $expertGreen, $alpha: .4);
  }
}
