@import '/src/styles/colors.scss';

.product__container {
  padding: 10px 20px;
  .product__header {
    margin-bottom: 24px;
    .product__status_text {
      font-size: 1.5rem;
      color: $white;
    }
    .product__status__green,
    .product__status__red {
      font-size: 1.5rem;
      padding: 6px 18px;
      margin-left: 8px;
      color: $white;
      border: none;
      border-radius: 8px;
    }
    .product__status__green {
      background-color: rgba($color: $green, $alpha: 0.9);
    }
    .product__status__red {
      background-color: rgba($color: $red, $alpha: 0.9);
    }
  }
  .grid__column {
    display: grid;
    align-content: flex-start;
  }
  .content__box {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    &__nopadding {
      padding: 0;
    }
    h4.box__section__title {
      color: $white;
      margin-bottom: 32px;
      font-size: 1.8rem;
    }

    &__calendar {
      max-height: calc(100vh - 300px);
      overflow-y: auto;
      overflow-x: hidden;
      background: url('/assets/bg-section.webp') no-repeat;
      background-position: bottom right;
      background-color: $expertGreen;
      padding: 16px;
      .product__timeline {
        transform: translateX(-24px);
      }
    }
    &__graph {
      background: #f3f3f3;
      margin: 0 0 16px;
      padding: 16px 16px 16px 0px;
      border-radius: 8px;
      position: relative;

      &__table {
        margin: 16px 0;
        span.color-green {
          color: $green;
        }
        span.color-orange {
          color: $dynamicOrange;
        }
      }
    }
    &__left {
      background: url('/assets/bg-section.webp') no-repeat;
      background-position: bottom right;
      background-color: $expertGreen;
      padding: 12px;
      .outstanding-amount-box {
        align-items: center;
        padding: 4px 0;
        margin-bottom: 8px;
        > p {
          font-size: 1.6rem;
          margin: 0 10px;
          font-weight: 500;
        }
        > span {
          font-size: 1.4rem;
          color: $lightGray;
          font-weight: 500;
        }
      }
      h4 {
        font-size: 1.4rem;
        color: $lightGray;
        text-transform: uppercase;
        &.section__title_margin {
          margin-top: 16px;
        }
      }
      &_section {
        margin-top: 8px;
        background-color: $white;
        padding: 8px 12px;
        border-radius: 8px;
      }
      .info__item {
        justify-content: space-between;
        align-items: baseline;
        padding: 10px 0;
        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }
        &__title {
          color: $expertGreen;
          margin-top: 12px;
          padding-bottom: 4px;
          border-bottom: solid 1px $lightGray;
        }
        p,
        label {
          margin: 0;
          color: $expertGreen;
          font-size: 1.4rem;
        }
        label {
          width: 100px;
          font-weight: 600;
          color: $expertGreen;
          flex: 1;
        }
        a {
          color: $white;
          &:hover {
            color: $goldenYellow;
          }
        }
      }
      .action_section {
        border-radius: 8px;
        margin-top: 12px;
        padding: 16px;
        align-items: center;
        background: url('/assets/author.webp') no-repeat;
        background-color: $dynamicOrange;
        background-position: bottom right;
        background-size: 50%;
        .userIcon {
          width: 60px;
          margin-right: 18px;
        }
        .desc {
          font-size: 1.8rem;
          font-weight: 500;
        }
        .connect_button {
          margin-top: 12px;
          width: 100%;
          font-size: 1.6rem;
          font-weight: 500;
          background-color: $white;
          color: $expertGreen;
          padding: 18px;
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }
  .pdf__viewer {
    background-color: $expertGreen;
    border-radius: 8px;
    > h4.box__section__title {
      margin: 16px;
      color: $white;
    }
    iframe {
      width: 100%;
      border: none;
      background-color: $white;
    }
  }
}
