$expertGreen: rgb(0, 37, 45);
$goldenYellow: rgb(255, 165, 64);
$text-color: rgb(253, 252, 252);
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$cleverPurple: rgb(37, 48, 127);
$darkBlue: rgb(0, 75, 92);
$dark-green: rgb(0, 92, 112);
$expertGreen200: rgb(61, 221, 255);
$expertGreen300: rgb(0, 159, 194);
$expertGreen500: rgb(0, 126, 153);
$table-row-color: rgb(0, 46, 56);

$green-positive: rgb(115, 209, 61);
$green: rgb(76, 175, 80);
$orange: rgb(255, 165, 0);
$red: rgb(244, 67, 54);
$blue: rgb(33, 150, 243);
$lime: rgb(205, 220, 57);
$indigo: rgb(63, 81, 181);

$red-muted: rgb(190, 40, 40);
$blue-muted: rgb(35, 114, 179);
$green-muted: rgb(65, 150, 67);
$orange-muted: rgb(202, 139, 22);

$deepOrange: rgb(244, 81, 30);
$grey100: rgb(245, 245, 245);
$grey200: rgb(224, 224, 224);
$grey300: rgb(224, 224, 224);
$grey400: rgb(189, 189, 189);
$grey800: rgb(89, 89, 89);
$lightBlue200: rgb(129, 212, 250);
$green100: rgb(185, 246, 202);
$darkerExpertGreen: rgb(11, 26, 29);
$dynamicOrange: rgb(255, 95, 69);
$organicBlue: rgb(0, 106, 153);
$yellow200: rgb(255, 245, 157);
$orange300: rgb(255, 138, 101);
$indigo200: rgb(159, 168, 218);
$lightGray: rgb(171, 172, 187);

