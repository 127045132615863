@import '/src/styles/colors.scss';

.add-underlyings-form {
    margin-bottom: 12px;
  .add-underlying-button {
    font-size: 1.2rem;
    padding: 4px 8px;
    border: none;
    background-color: $darkBlue;
  }
}
