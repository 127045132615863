@import '/src/styles/colors.scss';

.custom__table {
  .items__list {
    border-radius: 6px;
    &__header {
      padding: 16px 0;
    }
  }
  .filter-title {
    font-size: 1.8rem;
    color: $white;
  }
  .ant-table-wrapper .ant-table-column-sorter {
    .ant-table-column-sorter-down.active svg,
    .ant-table-column-sorter-up.active svg {
      color: rgba($color: $expertGreen, $alpha: 0.5);
    }
  }
  .ant-table-filter-column .ant-table-filter-trigger.active svg {
    color: $goldenYellow;
  }
  .filter-select .ant-select-selector {
    border: none;
    background-color: $darkerExpertGreen;
  }
  .ant-pagination-item-active {
    background-color: $expertGreen;
    border: solid 1px $expertGreen;
  }
  .ant-pagination-item-active a {
    color: $goldenYellow;
    &:hover {
      color: $white;
    }
  }
  .items-search-input input {
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

// Main table
#custom-table {
  background-color: transparent;
  .ant-table-row .ant-table-cell.ant-table-cell-row-hover {
    background-color: rgba($color: $expertGreen, $alpha: 1);
  }

  thead tr th {
    background-color: $expertGreen;
    color: white;
    font-size: 1.4rem;
    &::before {
      background-color: $white;
    }
  }
  tbody tr.ant-table-row {
    cursor: pointer;
    .group-name {
      font-size: 1.8rem;
      border-bottom: solid 2px $goldenYellow;
    }
    td {
      border-bottom: dashed 1px $white;
    }
    &:hover {
      .group-name {
        font-weight: 600;
        border-bottom: solid 3px $goldenYellow;
      }
    }
  }
  .table-row-odd td,
  .inner-table-row-odd td {
    background-color: rgba($color: $expertGreen, $alpha: 0.9);
    color: $white;
  }
  .table-row-even td,
  .inner-table-row-even td {
    background-color: rgba($color: $expertGreen, $alpha: 0.7);
    color: $white;
  }
  table td,
  table th {
    border: none;
  }

  .expanded__table-row {
    padding: 0;
  }
}

// Inner table
#expanded__table__inner__table--1 {
  background-color: transparent;
  margin: 0;
  table {
    margin-bottom: 10px;
    .eye-view-icon {
      color: $expertGreen;
    }
    .ant-table-row .ant-table-cell.ant-table-cell-row-hover {
      background: rgba($color: $white, $alpha: 1);
    }
    .ant-table-cell {
      border-radius: 0;
    }
    .ant-table-tbody .ant-table-cell{
      font-weight: 450;
    }
    thead tr th {
      background-color: $goldenYellow;
    }
    tr td.ant-table-cell {
      border-bottom: none;
    }
    .inner-table-row-even td {
      background-color: rgba(255, 255, 255, 0.85);
      color: $expertGreen;
    }
    .inner-table-row-odd td {
      background: rgba(198, 227, 228, 0.85);
      color: $expertGreen;
    }
  }
}

// Inner inner table
#expanded__table__inner__table--2 {
  .ant-table-row .ant-table-cell.ant-table-cell-row-hover {
    background: rgba($color: $white, $alpha: .8);
  }
  background-color: transparent;
  .ant-table-container {
    display: flex;
    justify-content: flex-end;
    > .ant-table-content {
      width: 60%;
    }
  }
  table {
    tr {
      &:hover td {
        color: $expertGreen;
      }
    }
    thead tr th {
      background-color: rgba(0, 37, 45, 0.8);
    }
    .inner-table-row-even td {
      background-color: rgba(255, 255, 255, 0.8);
      color: $expertGreen;
    }
    .inner-table-row-odd td {
      background: rgba(255, 255, 255, 1);
      color: $expertGreen;
    }
  }
}

.ant-table-column-has-sorters svg,
.ant-dropdown-trigger svg {
  color: white;
}

.date-filter-dropdown {
  padding: 12px 8px;
  button {
    margin-left: 12px;
    margin-top: 8px;
  }
}

.no-content {
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  color: $white;
}

.product-group-count {
  font-size: 1.2rem;
}
