@import '/src/styles/colors.scss';

.createProductPage {
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media (min-width: 768px) {
    padding: 10px 20px;
  }
  .create-product-form {
    margin-top: 28px;
  }
  &__header {
    margin-bottom: 16px;
  }
  &__formcol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  h2 {
    color: $goldenYellow;
    margin-bottom: 16px;
  }
  .form__group {
    width: 100%;
    border-radius: 8px;
    background: $expertGreen;
    margin-bottom: 24px;
    position: relative;
    &_content {
      padding: 8px 24px 24px;
    }
    .section__title {
      margin-bottom: 12px;
      text-transform: capitalize;
      font-size: 1.7rem;
      &-topmargin {
        margin-top: 32px;
      }
      &__background {
        background-color: $organicBlue;
        width: fit-content;
        padding: 2px 16px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 6px;
      }
    }
    &_general {
      .section__title {
        background-color: $goldenYellow;
      }
    }
    &_calendar {
      .section__title {
        background-color: $dynamicOrange;
      }
    }
  }
  .customField {
    width: 100%;
    margin-bottom: 12px;
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
    margin-top: 8px;

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(16px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .product_timeline_wrapper {
    margin: 16px 0;
    width: 100%;
    overflow-y: auto;
    padding: 24px 16px;
    background: url('/assets/bg-section.webp') no-repeat;
    background-position: bottom right;
    background-color: $expertGreen;
    border-radius: 8px;
    @media (min-width: 1360px) {
      max-height: calc(100vh - 400px);
    }
  }

  .submit_product_button {
    background-color: $goldenYellow;
    color: $black;
  }

  .ant-upload-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column; 
    .ant-upload-list-item-name {
      color: $expertGreen;
    }
  }
  .addNewField {
    margin-top: 16px;
  }
  .addNewEvent_wrapper {
    width: 100%;
    margin-top: 8px;
  }
  .input_event_type__label {
    margin-bottom: 4px;
  }
  .ant-upload-wrapper .ant-upload-btn .ant-upload-text {
    color: $expertGreen;
    font-size: 1.4rem;
  }
  .product-form-input {
    background-color: rgba($color: $white, $alpha: 0.2);
    border: none;
    &::placeholder {
      color: rgba($color: $white, $alpha: .5);
      font-size: 1.2rem;
    }
    input[disabled] {
      color: $white;
    }
  }
  .product-select-input .ant-select-selector {
    background-color: rgba($color: $white, $alpha: 0.25);
    border: none;
    .ant-select-selection-placeholder {
      color: rgba($color: $white, $alpha: .6);
      font-size: 1.2rem;
    }
  }
}
