@import '/src/styles/colors.scss';

.add-underlying-equity-form {
    padding: 4px 0;
    margin-top: 4px;
    border-radius: 4px;
}
.underlying-equity__item {
    margin-top : 8px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px 6px;
    border-radius: 4px;
}