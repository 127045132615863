.ant-select-item-option-content,
.language-switcher {
  .full-text {
    display: block;
  }
  .short-text {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .ant-select-item-option-content,
  .language-switcher {
    .full-text {
      display: none;
    }
    .short-text {
      display: block;
    }
  }
}
