button {
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    border: none;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    transition: 0.3s;
    cursor: pointer;
  }
  
  button:hover {
    background: linear-gradient(135deg, #feb47b, #ff7e5f);
    transform: scale(1.05);
  }
  
  .progress-bar {
    height: 10px;
    border-radius: 5px;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    box-shadow: 0px 0px 10px rgba(106, 17, 203, 0.5);
  }
  
  .json-container {
    background: rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 8px;
    font-family: "Courier New", monospace;
    color: #ffffff;
  }
  
  .chart-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 8px;
  }
  