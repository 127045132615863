@import '/src/styles/colors.scss';

.unwind_form_container {
  margin-top: 24px;
  background-color: $expertGreen;
  padding: 32px 56px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.5);
  &__header {
    margin-bottom: 24px;
  }
  h2 {
    margin-bottom: 16px;
  }
  .notional {
    &_amount {
      margin-bottom: 16px;
      margin-top: -16px;
      font-size: 1.4rem;
    }
  }
}
