.content {
  display: flex;
  flex-direction: column;
  color: white;
}
.title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.titleLabel {
  font-size: 22px;
  font-weight: bold;
  color: white;
}
.label {
  font-weight: 600;
}
.cardWrapper {
  padding: 2px;
  border-radius: 15px;
  width: 269px; /* cardWidth + padding * 2 */
  overflow-y: auto;
}
.scheduleWrapper {
  width: 269px;
  border-radius: 15px;
}
.scheduleCard {
  width: 265px;
  border-radius: 15px;
  background-color: var(--expertGreen);
  height: 70vh;
}
.card {
  border-radius: 15px;
  background-color: var(--expertGreen);
  width: 265px;
}
.cardHeader {
  border-radius: 15px 0 5px 0;
  width: fit-content;
  padding: 0 15px;
}
.cardHeader > h2 {
  color: white;
  text-align: center;
  width: fit-content;
}
.cardContent {
  padding: 20px 10px;
}
.lightgrey {
  background-color: lightgrey;
}
.logoIssuers {
  width: 100px;
}
.rowClassname {
  background-color: rgba(0, 37, 45, 1);
  color: white;
}
.fieldBgColor {
  background-color: rgba(255, 255, 255, 0.3);
}
.pricingTitle {
  color: white;
  font-weight: 700;
  border-bottom: 3px solid white;
  width: fit-content;
}
.labelResult {
  font-size: 25px;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif;
  color: var(--expertGreen);
}
.cardWrapperResult {
  padding: 2px;
  border-radius: 15px;
  width: 45vw;
}
.cardResult {
  border-radius: 15px;
  background-color: var(--expertGreen);
}
.cardContentResult {
  padding: 0 20px 20px 20px;
}
.sectionTitle {
  color: white;
  font-family: 'Work Sans', sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  border-bottom: 3px solid var(--goldenYellow);
  width: fit-content;
  height: 50px;
}
.cardWrapperSubmit {
  padding: 2px;
  border-radius: 15px;
  margin-top: 3vh;
}
.cardSubmit {
  border-radius: 15px;
  background-color: var(--expertGreen);
  padding-bottom: 20px;
}
.tableLabel {
  font-family: 'Work Sans', sans-serif;
}
.best-result {
  color: var(--dynamicOrange);
  text-decoration: underline;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif;
}
.issuersWrapper {
  border-radius: 15px;
  padding: 2px;
}
.issuersCard {
  border-radius: 15px;
  background-color: var(--expertGreen);
  max-height: 60vh;
  overflow: auto;
}
.issuerWrapper {
  padding: 5px;
  border-radius: 15px;
}
.issuerWrapper:hover {
  background-color: var(--dynamicOrange);
}
.selectedIssuer {
  margin: -2px;
  border: 2px solid white;
  border-radius: 15px;
}
.disabledIssuer {
  opacity: 0.3;
}
.disabledIssuer:hover {
  background-color: unset;
}
.issuer {
  width: 200px;
  height: 60px;
  cursor: pointer;
}
.selectAllIssuers {
  align-items: center;
  color: white;
  margin-left: 1%;
  font-size: 22px;
}

.pricing-product-type-select {
  width: fit-content;
  margin: 0 0 0 52px;
}
.mainContent {
  column-gap: 3vw;
}
.historyButton {
  width: 60px;
  height: 60px;
  top: 80px;
  right: 20px;
}
.ant-drawer .drawer-history {
  background-color: var(--expertGreen);
  width: 90vw;

  .ant-drawer-close {
    color: white;
    width: auto;
  }
}
.formContent {
  column-gap: 2vw;
  .add-underlying-equity-form .ant-select-selector {
    border: none;
    outline: none;
    appearance: none;
    height: 25px;
  }
  .coupon-field {
    width: 158px;
  }

  .ant-form-item-control-input-content > .ant-input-number-disabled,
  // pound 0,4,0 to override antd styles
  .card .ant-select-disabled > .ant-select-selector {
    background: rgba(255, 255, 255, 0.15);
    &:hover {
      background: transparent;
    }
  }

  .form-switch-and-field {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &.form-switch-disabled {
      .ant-form-item {
        display: none;
      }
    }
    .ant-form-item .ant-form-item-row {
      width: 100%;
    }
  }

  .form-item-switch {
    margin: 0;

    .ant-form-item-row {
      flex-direction: row;
      align-items: center;

      .ant-form-item-control {
        flex: none;
      }

      .ant-form-item-label {
        flex: 1;
      }
    }
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
    padding: 5px 0;

    .ant-input-number,
    .pricer-field,
    .ant-select-selector,
    .ant-form-item-control-input {
      width: 100%;
      border: none;
      outline: none;
      appearance: none;
      height: 25px;
      input {
        width: 100%;
      }
    }
  }
}
