@import '/src/styles/colors.scss';

.valuation {
  padding: 8px 16px;
  background-color: rgba($expertGreen, 0.5);
  border-radius: 12px;
  font-size: 1.4rem;
  color: $white;
  &.green_positive {
    outline: $green-muted medium solid;
    outline-offset: -3px;
  }
  &.green_normal {
    outline: $blue-muted medium solid;
    outline-offset: -3px;
  }
  &.orange {
    outline: $orange-muted medium solid;
    outline-offset: -3px;
  }
  &.red {
    outline: $red-muted medium solid;
    outline-offset: -3px;
  }
}
