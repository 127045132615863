@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300..900;1,100..900&display=swap);
@import './styles/colors.scss';

html {
  font-size: 10px;
}

:root{
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: unset;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
div,
ol,
span,
input,
textarea,
div,
class,
select,
section,
header,
footer,
img {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

button,
input,
textarea,
select {
  font: inherit;
}

img,
picture,
svg,
canvas {
  display: block;
  max-inline-size: 100%;
  block-size: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
