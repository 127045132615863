@import '/src/styles/colors.scss';

.event_manager_container {
  .actual__flow__input {
    position: relative;
    justify-content: center;
    width: 90px;
  }
  .status__select {
    .ant-select-selector {
      border: none;
      outline: none;
    }
    .ant-select-arrow,
    .ant-select-selection-item {
      color: $black;
    }
    &.green_positive .ant-select-selector {
      background: $green-positive;
      color: $black;
    }
    &.red .ant-select-selector {
      background: $red;
      color: $black;
    }
  }
}
