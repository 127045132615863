@import '/src/styles/colors.scss';

.createTradePage {
  margin-top: 24px;
  background-color: $expertGreen;
  padding: 32px 48px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.5);
  &__header {
    margin-bottom: 16px;
  }
  .trade-form-input {
    background-color: rgba($color: $white, $alpha: 0.2);
    border: none;
    &::placeholder {
      color: rgba($color: $white, $alpha: 0.5);
      font-size: 1.2rem;
    }
    input[disabled] {
      color: $white;
    }
  }
  .trade-select-input .ant-select-selector {
    background-color: rgba($color: $white, $alpha: 0.25);
    border: none;
    .ant-select-selection-placeholder {
      color: rgba($color: $white, $alpha: 0.6);
      font-size: 1.2rem;
    }
  }
  h2 {
    color: $white;
    margin-bottom: 16px;
  }
  h4 {
    font-size: 16px;
    &.section__title-topmargin {
      margin-top: 32px;
    }
  }
  .section__title {
    color: $text-color;
    margin-bottom: 16px;
    &__topmargin {
      margin-top: 48px;
    }
  }
  .section__check {
    margin-bottom: 12px;
    &_topmargin {
      margin-top: 32px;
    }
    .section__check__title {
      color: $text-color;
    }
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .section_info {
    color: $white;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  .submit_button {
    margin-top: 8px;
    background-color: $goldenYellow;
  }
  .ant-picker {
    width: 100%;
  }
  .select_date {
    display: flex;
    > .ant-space-item {
      flex: 1;
    }
  }
  .custodians__infos {
    background-color: $organicBlue;
    padding: 8px 16px;
    border-radius: 8px;
    margin-top: 8px;
  }
  .custodian__name {
    margin: 8px 0;
  }
  &__tbd {
    height: 32px;
    &__button {
      padding: 0;
      text-decoration: underline;
    }
    &__text {
      font-size: 12px;
    }
  }
}
